
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import 'core-js/proposals/reflect-metadata';
import '../styles.css';
import { config } from '@fortawesome/fontawesome-svg-core/index';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { NextPage } from 'next/types';
import { AppProps } from 'next/app';
import NProgress from 'nprogress';
import parse from 'html-react-parser';
import { useCommon } from '../lib/common';
import { useUser } from '../lib/user';
import { getCmsPageData, pageIncludesSportsWidgets, useAnalyticSettings, useHtmlHeadSection, useSportsBettingSettings, useTheme, } from '../lib/content';
import useTranslation from 'next-translate/useTranslation';
import { Translate } from 'next-translate';
import useHotjar from '../hooks/useHotjar';
import { usePageCookies } from '../hooks/usePageCookies';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';
import { isNullOrEmpty } from '../util/string';
import useGoogleTagManager from '../hooks/useGoogleTagManager';
import usePromotionCode from '../hooks/usePromotionCode';
import useSportradarWidgetSettings from '../hooks/useSportradarWidgetSettings';
import CommonProvider from '../context/common.context';
import useIncomeAccessBtag from '../hooks/useIncomeAccessBtag.ts';
import LayoutProvider from '../context/layout.context';
import AppProvider from '../context/app.context';
config.autoAddCss = false;
const BetslipProvider = dynamic(() => import('../context/betslip.context'));
const SportsProvider = dynamic(() => import('../context/sports.context'));
const LiveProvider = dynamic(() => import('../context/live.context'));
const PaymentProvider = dynamic(() => import('../context/payment.context'));
const CookieBanner = dynamic(() => import('../components/CookieBanner'));
const AppLoader = dynamic(() => import('../components/layout/AppLoader'));
//Binding events.
NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
export type NextPageWithLayout = NextPage & {
    getLayout?: (t: Translate, pageProps: any, page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};
const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const router = useRouter();
    const { t, lang } = useTranslation();
    const [initialized, setInitialized] = useState(false);
    const [themeColor, setThemeColor] = useState('#000000');
    const [manifestUrl, setManifestUrl] = useState<string>('');
    const getLayout = Component.getLayout || ((_t, _pageProps, page) => page);
    const themeVariables = useTheme();
    const frontendSettings = pageProps.frontendSettings || {};
    const frontendResources = pageProps.frontendResources || {};
    const htmlHeadSection = useHtmlHeadSection();
    const parsedHtmlHeadSection = htmlHeadSection != null && typeof htmlHeadSection === 'string' ? parse(htmlHeadSection) : null;
    const commonData = useCommon();
    const sportsBettingSettings = useSportsBettingSettings();
    const sportRadarWidgetSettings = useSportradarWidgetSettings();
    const { user } = useUser();
    const language = commonData?.languages?.find((language) => language.shortSign === lang);
    const [essentialCookiesAccepted, performanceCookiesAccepted, setCookieValues] = usePageCookies();
    const analyticSettings = useAnalyticSettings();
    useHotjar(analyticSettings?.hotjar, analyticSettings?.consentRequired, performanceCookiesAccepted);
    useGoogleAnalytics(analyticSettings?.ga, analyticSettings?.consentRequired, performanceCookiesAccepted);
    useGoogleTagManager(analyticSettings?.gtm, analyticSettings?.consentRequired, performanceCookiesAccepted);
    usePromotionCode();
    useIncomeAccessBtag();
    useEffect(() => {
        addConsoleMessage();
        window.addEventListener('resize', updateDocumentHeight);
        updateDocumentHeight();
        return () => {
            window.removeEventListener('resize', updateDocumentHeight);
        };
    }, []);
    useEffect(() => {
        if (commonData != null && user != null && sportsBettingSettings != null && themeVariables != null) {
            setInitialized(true);
        }
    }, [commonData, user, sportsBettingSettings, themeVariables]);
    useEffect(() => {
        if (themeVariables != null) {
            setCmsThemeVariables();
            buildManifestJSON();
        }
    }, [themeVariables]);
    useEffect(() => {
        if (router?.pathname && themeVariables)
            setThemeColor(getBrowserThemeColor());
    }, [router?.pathname, themeVariables]);
    const addConsoleMessage = () => {
        console.log('%cHOLD UP! READ THIS!', 'color: red; font-family:sans-serif; font-size: 48px; font-weight: bold;');
        console.log("If someone told you to copy/paste something here they are most likely trying to scam you.\nPasting anything in here could give attackers access to your account.\nUnless you know exactly what you are doing, close this window and don't open it again. We're not joking! Close the damn window!");
        console.log("You're still here? A little nosy aren't you?\nShould you know exactly what you're doing, why not come work with us? https://arland.at/en/career/");
        console.log('%cPowered by Arland', 'color: #FF8B26; font-family:sans-serif; font-size: 20px; font-weight: bold;');
    };
    const getBrowserThemeColor = () => {
        let themeColor = '#ffffff';
        switch (router.pathname) {
            case '/':
                themeColor = '#000000';
                break;
            case '/account/login':
            case '/account/register':
            case '/account/activate/[token]':
            case '/account/reset-password':
            case '/account/reset-password/[token]':
                themeColor = getComputedStyle(document.documentElement).getPropertyValue('--background');
                break;
            case '/account/logout':
                themeColor = getComputedStyle(document.documentElement).getPropertyValue('--app-loader-background');
                break;
            case '/onboarding':
                themeColor = getComputedStyle(document.documentElement).getPropertyValue('--onboarding-background');
                break;
            default:
                themeColor = getComputedStyle(document.documentElement).getPropertyValue('--header');
        }
        return themeColor;
    };
    const updateDocumentHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    const setCmsThemeVariables = () => {
        if (themeVariables == null)
            return;
        let data = '';
        for (let themeVariable of themeVariables) {
            data += `--${themeVariable.identifier}: ${themeVariable['value']};\n`;
        }
        document.documentElement.style.cssText = data;
        updateDocumentHeight();
    };
    const buildManifestJSON = () => {
        const backgroundColor = themeVariables?.find((variable) => variable.identifier === 'background')?.value ?? '#ff0000';
        const themeColor = themeVariables?.find((variable) => variable.identifier === 'primary')?.value ?? '#00ff00';
        const icons: {
            src?: string;
            sizes: string;
            type: string;
        }[] = [];
        if (frontendResources?.icons?.size16)
            icons.push({
                src: frontendResources.icons.size16.fileUrl,
                sizes: '16x16',
                type: frontendResources.icons.size16.contentType,
            });
        if (frontendResources?.icons?.size32)
            icons.push({
                src: frontendResources.icons.size32.fileUrl,
                sizes: '32x32',
                type: frontendResources.icons.size32.contentType,
            });
        if (frontendResources?.icons?.size72)
            icons.push({
                src: frontendResources.icons.size72.fileUrl,
                sizes: '72x72',
                type: frontendResources.icons.size72.contentType,
            });
        if (frontendResources?.icons?.size96)
            icons.push({
                src: frontendResources.icons.size96.fileUrl,
                sizes: '96x96',
                type: frontendResources.icons.size96.contentType,
            });
        if (frontendResources?.icons?.size128)
            icons.push({
                src: frontendResources.icons.size128.fileUrl,
                sizes: '128x128',
                type: frontendResources.icons.size128.contentType,
            });
        if (frontendResources?.icons?.size144)
            icons.push({
                src: frontendResources.icons.size144.fileUrl,
                sizes: '144x144',
                type: frontendResources.icons.size144.contentType,
            });
        if (frontendResources?.icons?.size152)
            icons.push({
                src: frontendResources.icons.size152.fileUrl,
                sizes: '152x152',
                type: frontendResources.icons.size152.contentType,
            });
        if (frontendResources?.icons?.size192)
            icons.push({
                src: frontendResources.icons.size192.fileUrl,
                sizes: '192x192',
                type: frontendResources.icons.size192.contentType,
            });
        if (frontendResources?.icons?.size384)
            icons.push({
                src: frontendResources.icons.size384.fileUrl,
                sizes: '384x384',
                type: frontendResources.icons.size384.contentType,
            });
        if (frontendResources?.icons?.size512)
            icons.push({
                src: frontendResources.icons.size512.fileUrl,
                sizes: '512x512',
                type: frontendResources.icons.size512.contentType,
            });
        const manifestJSON = {
            name: frontendSettings?.pwaSettings?.name ?? window.location.origin,
            short_name: frontendSettings?.pwaSettings?.nameShort ?? window.location.origin,
            icons,
            theme_color: themeColor,
            background_color: backgroundColor,
            start_url: '/',
            display: 'standalone',
            orientation: 'portrait',
        };
        const stringManifest = JSON.stringify(manifestJSON);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        setManifestUrl(URL.createObjectURL(blob));
    };
    const cmsPageData = getCmsPageData(pageProps?.cmsPage);
    const includesSportsWidgets = pageIncludesSportsWidgets(pageProps?.cmsPage);
    const metaTitle = pageProps.metaTitle ?? cmsPageData?.metaTitle;
    const metaDescription = pageProps.metaDescription ?? cmsPageData?.metaDescription;
    const metaKeywords = cmsPageData?.metaKeywords;
    const ogFallbackImage = frontendResources?.logos?.mainLogo?.fileUrl ??
        frontendResources?.icons?.size512?.fileUrl ??
        frontendResources?.icons?.size384?.fileUrl ??
        frontendResources?.icons?.size192?.fileUrl ??
        frontendResources?.logos?.favIcon?.fileUrl ??
        null;
    const ogFacebookImage = frontendResources?.openGraph?.logoRatio40x21?.fileUrl ?? ogFallbackImage;
    const ogTwitterImage = frontendResources?.openGraph?.logoRatio2x1to2?.fileUrl ?? ogFallbackImage;
    const ogTitle = metaTitle;
    const ogDescription = metaDescription;
    const jsonLD = pageProps?.jsonLD;
    const canonicalUrl = typeof window !== 'undefined' && window?.location?.origin
        ? `${window.location.origin}/${lang}${router.asPath}`
        : undefined;
    const applicationName = frontendSettings?.pwaSettings?.name ??
        (typeof window !== 'undefined' && window?.location?.origin ? window.location.origin : null);
    return (<>
      <Head>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"/>
        <meta name="format-detection" content="telephone=no"/>

        {applicationName && <meta name="application-name" content={applicationName}/>}
        {applicationName && <meta name="apple-mobile-web-app-title" content={applicationName}/>}
        <meta name="mobile-web-app-capable" content="yes"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="default"/>
        {themeColor && <meta name="theme-color" content={themeColor}/>}
        {!isNullOrEmpty(manifestUrl) && <link rel="manifest" href={manifestUrl}/>}

        {frontendResources?.logos?.favIcon && (<>
            <link rel="shortcut icon" href={frontendResources.logos.favIcon.fileUrl}/>
            <link rel="mask-icon" sizes="any" href={frontendResources.logos.favIcon.fileUrl} color="#FFFFFF"/>
          </>)}
        {frontendResources?.icons?.size180 && (<link rel="apple-touch-icon" href={frontendResources.icons.size180.fileUrl}/>)}
        {frontendResources?.icons?.size16 && (<link rel="icon" type="image/png" sizes="16x16" href={frontendResources.icons.size16.fileUrl}/>)}
        {frontendResources?.icons?.size32 && (<link rel="icon" type="image/png" sizes="32x32" href={frontendResources.icons.size32.fileUrl}/>)}
        {frontendResources?.icons?.size72 && (<link rel="icon" type="image/png" sizes="72x72" href={frontendResources.icons.size72.fileUrl}/>)}
        {frontendResources?.icons?.size96 && (<link rel="icon" type="image/png" sizes="96x96" href={frontendResources.icons.size96.fileUrl}/>)}
        {frontendResources?.icons?.size128 && (<link rel="icon" type="image/png" sizes="128x128" href={frontendResources.icons.size128.fileUrl}/>)}
        {frontendResources?.icons?.size144 && (<link rel="icon" type="image/png" sizes="144x144" href={frontendResources.icons.size144.fileUrl}/>)}
        {frontendResources?.icons?.size152 && (<link rel="icon" type="image/png" sizes="152x152" href={frontendResources.icons.size152.fileUrl}/>)}
        {frontendResources?.icons?.size180 && (<link rel="icon" type="image/png" sizes="180x180" href={frontendResources.icons.size180.fileUrl}/>)}
        {frontendResources?.icons?.size192 && (<link rel="icon" type="image/png" sizes="192x192" href={frontendResources.icons.size192.fileUrl}/>)}
        {frontendResources?.icons?.size384 && (<link rel="icon" type="image/png" sizes="384x384" href={frontendResources.icons.size384.fileUrl}/>)}
        {frontendResources?.icons?.size512 && (<link rel="icon" type="image/png" sizes="512x512" href={frontendResources.icons.size512.fileUrl}/>)}

        {frontendResources?.splashScreen?.iPhone5 && (<link href={frontendResources.splashScreen.iPhone5.fileUrl} media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPhone6 && (<link href={frontendResources.splashScreen.iPhone6.fileUrl} media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPhonePlus && (<link href={frontendResources.splashScreen.iPhonePlus.fileUrl} media="screen and (device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPhoneX && (<link href={frontendResources.splashScreen.iPhoneX.fileUrl} media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPhoneXr && (<link href={frontendResources.splashScreen.iPhoneXr.fileUrl} media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPhoneXsMax && (<>
            <link href={frontendResources.splashScreen.iPhoneXsMax.fileUrl} media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" rel="apple-touch-startup-image"/>
          </>)}
        {frontendResources?.splashScreen?.iPhone12Plus && (<link href={frontendResources.splashScreen.iPhone12Plus.fileUrl} media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPhone12ProPlus && (<link href={frontendResources.splashScreen.iPhone12ProPlus.fileUrl} media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPad && (<link href={frontendResources.splashScreen.iPad.fileUrl} media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPadPro1 && (<link href={frontendResources.splashScreen.iPadPro1.fileUrl} media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPadPro2 && (<link href={frontendResources.splashScreen.iPadPro2.fileUrl} media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}
        {frontendResources?.splashScreen?.iPadPro3 && (<link href={frontendResources.splashScreen.iPadPro3.fileUrl} media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" rel="apple-touch-startup-image"/>)}

        {analyticSettings?.ga?.enabled && (performanceCookiesAccepted || !analyticSettings?.consentRequired) && (<>
            <script async src={'https://www.googletagmanager.com/gtag/js?id=' + analyticSettings.ga.code}></script>
            <script dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${analyticSettings.ga.code}');`,
            }}></script>
          </>)}

        {analyticSettings?.sportRadar?.enabled &&
            !isNullOrEmpty(analyticSettings?.sportRadar?.clientId) &&
            (performanceCookiesAccepted || !analyticSettings?.consentRequired) && (<script dangerouslySetInnerHTML={{
                __html: `
                              function sportRadarTagManager(a, b, c, d, e, f) {
                                a[e] = a[e] || [];
                                a.x = b.createElement(c);
                                a.y = b.getElementsByTagName(c)[0];
                                x.src = d+" ? id = "+f;
                                x.setAttribute("data-srtmn",e);
                                y.parentNode.insertBefore(x,y);
                              };
                              function srtmCommands(){sportRadarTagManager(window, document, "script", "https://tm.ads.sportradar.com/dist/tag-manager.js", arguments[0],
                              ${analyticSettings?.sportRadar?.clientId})};
                              `,
            }}></script>)}

        {sportRadarWidgetSettings?.enabled && language && (<script dangerouslySetInnerHTML={{
                __html: `
                            (function (a, b, c, d, e, f, g, h, i) {
                              a[e] ||
                                ((i = a[e] =
                                  function () {
                                    ;(a[e].q = a[e].q || []).push(arguments)
                                  }),
                                (i.l = 1 * new Date()),
                                (i.o = f),
                                (g = b.createElement(c)),
                                (h = b.getElementsByTagName(c)[0]),
                                (g.async = 1),
                                (g.src = d),
                                g.setAttribute('n', e),
                                h.parentNode.insertBefore(g, h))
                            })(window, document, 'script', 'https://widgets.sir.sportradar.com/${sportRadarWidgetSettings.clientId}/widgetloader', 'SIR', {
                              language: '${language.shortSign}',
                              theme: false
                            })
                            `,
            }}></script>)}

        {canonicalUrl && <link rel="canonical" href={canonicalUrl}/>}

        {metaTitle && <title>{metaTitle}</title>}
        {metaKeywords && <meta name="keywords" content={metaKeywords}/>}
        {metaDescription && <meta name="description" content={metaDescription}/>}

        <meta property="og:type" content="website"/>
        {ogTitle && (<>
            <meta key="ogTitle" property="og:title" content={ogTitle}/>
            <meta key="ogTitle" property="twitter:title" content={ogTitle}/>
          </>)}
        {ogDescription && (<>
            <meta key="ogDescription" property="og:description" content={ogDescription}/>
            <meta key="ogDescription" property="twitter:description" content={ogDescription}/>
          </>)}
        {ogFacebookImage && <meta property="og:image" content={ogFacebookImage}/>}
        {ogTwitterImage && (<>
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:image" content={ogTwitterImage}/>
          </>)}

        {jsonLD && (<script id="json-ld" type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }} key="json-ld"/>)}

        {<script src="https://cdn.geocomply.com/273/gc-html5.js" type="text/javascript"/>}

        {cmsPageData?.customHtmlHead &&
            typeof cmsPageData.customHtmlHead === 'string' &&
            parse(cmsPageData?.customHtmlHead)}

        {parsedHtmlHeadSection && parsedHtmlHeadSection}
      </Head>

      <AppProvider frontendSettings={frontendSettings} frontendResources={frontendResources}>
        {themeVariables && (<>
            {initialized ? (<LayoutProvider>
                <CommonProvider currencies={commonData?.currencies} languages={commonData?.languages} timeZones={commonData?.timeZones} countries={commonData?.countries} nodeCountries={commonData?.nodeCountries}>
                  {analyticSettings?.consentRequired && router.pathname != '/privacy' && (<CookieBanner essentialCookiesAccepted={essentialCookiesAccepted} performanceCookiesAccepted={performanceCookiesAccepted} setCookieValues={setCookieValues}/>)}

                  {router.pathname.includes('/sports') ||
                    router.pathname.includes('/account/bets') ||
                    router.pathname.includes('/favorites') ||
                    includesSportsWidgets ? (<LiveProvider liveEnabled={sportsBettingSettings?.liveEnabled} language={language}>
                      {router.pathname.includes('/sports') ||
                        router.pathname.includes('/favorites') ||
                        includesSportsWidgets ? (<SportsProvider>
                          <BetslipProvider>{getLayout(t, pageProps, <Component {...pageProps}/>)}</BetslipProvider>
                        </SportsProvider>) : (<>{getLayout(t, pageProps, <Component {...pageProps}/>)}</>)}
                    </LiveProvider>) : router.pathname.includes('/payment') ? (<PaymentProvider>{getLayout(t, pageProps, <Component {...pageProps}/>)}</PaymentProvider>) : (<>{getLayout(t, pageProps, <Component {...pageProps}/>)}</>)}
                </CommonProvider>
              </LayoutProvider>) : (<AppLoader />)}
          </>)}
      </AppProvider>
    </>);
};
const __Next_Translate__Page__193e4e0b7e2__ = App;

    export default __appWithI18n(__Next_Translate__Page__193e4e0b7e2__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  